.DeviceList {
  flex: 1;
  .card {
    .card-header {
      position: sticky;
      border: 0;
      top: -15px;

      .card-subtitle {
        .row {
          .disable-text-highlighting {
            -webkit-user-select: none; /* Chrome, Safari, and Opera */
            -moz-user-select: none; /* Firefox */
            -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
            -ms-user-select: none; /* IE */
            -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
          }
        }
      }
    }
  }
}
