.device-register-success.popup {
  position: fixed;
  //width: 100vw;
  //height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;

  .popup-inner {
    left: 30vw;
    right: 30vw;
    top: 25vh;
    bottom: 45vh;
    margin: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgb(97, 97, 97);
    min-height: 500px;
    min-width: 500px;

    .window-head {
      display: flex;
      background-color: rgb(197, 195, 195);
      border-radius: 10px 10px 0px 0px;

      .head-title {
        margin: 10px;
      }

      .head-filler {
        flex: 1;
      }

      .head-cancel {
        padding: 10px 15px;
        align-self: center;
        text-align: center;
        border: none;
        cursor: pointer;
      }

      .head-cancel:hover {
        background: rgb(170, 170, 170);
        border-radius: 2px 10px 2px 2px;
      }
    }

    .window-body {
      display: flex;

      .window-body-left {
        flex: 1.5;
        align-self: center;
        margin-top: 25px;
        margin-left: 25px;

        color: green;
        font-weight: bold;
        font-style: oblique;
      }

      .window-body-right {
        flex: 3;
        margin-top: 25px;

        display: flex;
        flex-direction: column;

        .right-item.title {
          font-size: large;
          font-weight: bold;
          margin-bottom: 30px;
        }

        .right-item.table {
          display: flex;
          flex-direction: row;

          .table-column {
            .table-item {
              margin-bottom: 5px;
            }

            .table-item.right {
              margin-left: 20px;
            }
          }
        }

        .right-item.label-form {
          display: flex;
          flex-direction: row;

          .form-item {
            margin-right: 5px;
          }
        }

        .right-item.register-another-btn {
          margin-top: 50px;
          width: 10vw;
        }

        .right-item.close-btn {
          width: 10vw;
        }

        .right-item {
          margin-top: 10px;
        }
      }
    }
  }
}
