.stage--select {
  width: 50%;
  color: #000;
}

.register-device.popup {
  position: fixed;
  //width: 100vw;
  //height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;

  .popup-inner {
    left: 30vw;
    right: 30vw;
    top: 25vh;
    bottom: 45vh;
    margin: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgb(97, 97, 97);
    min-height: 500px;
    min-width: 500px;

    .window-head {
      display: flex;
      background-color: rgba(175, 194, 202, 0.836);
      border-radius: 10px 10px 2px 2px;
      border-bottom: 1px solid rgba(176, 186, 192, 0.842);

      .head-title {
        margin: 10px;
        font-weight: bold;
      }

      .head-filler {
        flex: 1;
      }

      .head-cancel {
        padding: 10px 15px;
        align-self: center;
        text-align: center;
        border: none;
        cursor: pointer;
      }

      .head-cancel:hover {
        background: rgb(170, 170, 170);
        border-radius: 2px 10px 2px 2px;
      }
    }

    .window-body {
      display: flex;

      .window-body-left {
        flex: 1;
        justify-self: center;
        align-self: center;
        margin-top: 25px;
        margin-left: 25px;
      }

      .window-body-right {
        flex: 1;
        display: flex;
        flex-direction: column;

        .right-content.qrcode-text {
          margin-top: 20px;
          font-size: small;
          width: 400px;
          align-self: center;
        }

        .right-content.qrcode {
          border: 1px solid rgba(182, 182, 182, 0.726);
          width: 300px;
        }
      }
    }
  }
}
