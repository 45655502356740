.SideNavigation {
  display: flex;
  flex-direction: column;
  background: #0091cd;
  flex: 0 0 25%;
  max-width: 300px;
  min-width: 150px;
  overflow: auto;

  .navitem {
    padding: 10px;
    color: #fff;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    cursor: pointer;
    font-size: 0.8em;
    display: flex;
    align-items: center;

    &.logo {
      display: flex;
      align-items: center;
    }

    &.static {
      background-color: #0089c4;
    }

    &.orgunit-selector {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .logo-icon {
      width: 50px;
    }

    .app-name {
      line-height: 1.1;
      margin-left: 6px;
      font-size: 10px;
    }

    span {
      margin: 0 5px;
    }
  }

  .navitem:not(.static):hover {
    background: #0089c4;
  }

  .navitem.nav-spacer {
    flex: 1;
    cursor: default;
  }

  .navitem.device-register:active {
    background: #1aa1db;
  }

  .navitem.station-devices:active {
    background: #1aa1db;
  }

  .navitem.station-devices.activeComp {
    background: #1aa1db;
  }

  .navitem.device-user:active {
    background: #1aa1db;
  }

  .navitem.device-user.activeComp {
    background: #1aa1db;
  }

  .navitem.nav-spacer:hover {
    background: #0091cd;
  }

  .sidebar--section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .toggle-btn {
      color: #fff;
      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        display: flex;
        font-size: 14px;
        align-items: center;
        i {
          color: #333;
        }
        span {
          margin-left: 5px;
        }
      }
    }

    .fab,
    .fas {
      color: var(--primary) !important;
    }
  }
}
