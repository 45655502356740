.deviceSetting.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .popup-inner {
    left: 35vw;
    right: 35vw;
    top: 25vh;
    bottom: 50vh;
    margin: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgb(97, 97, 97);
    min-height: 200px;
    min-width: 450px;
    display: flex;
    flex-direction: column;

    .window-head {
      display: flex;
      background-color: rgba(175, 194, 202, 0.836);
      border-radius: 10px 10px 2px 2px;
      border-bottom: 1px solid rgba(176, 186, 192, 0.842);

      .head-title {
        margin: 10px;
        font-weight: bold;
      }

      .head-filler {
        flex: 1;
      }

      .head-cancel {
        padding: 10px 15px;
        align-self: center;
        text-align: center;
        border: none;
        cursor: pointer;
      }

      .head-cancel:hover {
        background: rgb(170, 170, 170);
        border-radius: 2px 10px 2px 2px;
      }
    }
    .window-body {
      display: flex;
      height: 100%;
      border-radius: 0px 0px 10px 10px;

      .window-body-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .left-body-text {
          margin-top: 20px;
          flex: 1;
        }

        .table {
          display: flex;
          flex: 3;
          flex-direction: row;
          margin-left: 20px;

          .table-right {
            margin-left: 10px;

            .table-item.item-4 {
              margin-top: 15px;
            }
          }

          .table-left {
            .table-item.item-4 {
              margin-top: 15px;
            }
          }
        }
      }

      .window-body-right {
        display: flex;
        flex-direction: column;
        border-radius: 0px 0px 10px 0px;

        .body-filler {
          flex: 1;
        }

        .buttons {
          margin: 10px;

          .confirm-btn {
            height: 50px;
            width: 100px;
            border-radius: 10px 10px 10px 10px;
          }

          .cancel-btn {
            height: 50px;
            width: 100px;
            border-radius: 10px 10px 10px 10px;
          }
        }
      }
    }
  }
}
