$form-feedback-valid-color: #ced4da;
$primary: #0091cd;
$link-color: #0091cd;
$gray: #eceff1;
$muted: #333334;

@import "/node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "/node_modules/bootstrap/scss/bootstrap";
@import "modules/scss/inputs.scss";
@import "modules/scss/tables.scss";
@import "modules/scss/cards.scss";
@import "modules/scss/modals.scss";

.App {
  display: flex;
  flex: 1;
  height: 100vh;

  #reach-router {
    width: 100%;
    .Main {
      display: flex;
      flex: 1;
      height: 100%;
      padding: 15px 0;
      background: $gray;
      overflow-y: scroll;
    }
  }
}

a,
button,
.btn {
  text-decoration: none;
  &.hover,
  &:hover,
  &.focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    text-decoration: underline;
  }
}

.btn-primary, .btn-outline-primary {
  &:hover, &:active, &.active {
    color: white !important;
  }
}

.btn-primary {
  color: white;
}

.bg-info {
  background-color: #17a2b8!important;
}

.ActionButtons {
  .btn,
  button {
    margin: 3px;
  }
}

.KeyValuePair {
  margin: 10px 0;
  padding: 10px 0;

  .key {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    color: #999;
    font-weight: bold;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  background-color: cornflowerblue;
}

.flex-row.inner-app {
  height: 100%;
  display: flex;
  overflow: auto;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.card-header {
  padding: 0.75rem 1.25rem;
}

