.card {
  border-radius: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: rgb(254, 254, 254);

  .card-header {
    border: 0;
    background: white;

    .card-title {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}
